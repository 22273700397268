

















































.privacy-policy {
  .doc-title {
    display: flex;
    padding: 15px;
    // border-radius: 15px 15px 0 0;
    background: #66999b;
  }
  .ps-container {
    height: 600px !important;
  }
  h2,
  h1,
  h3,
  h4 {
    margin: 20px 0;
  }

  .scroll-area {
    background: white;
  }
  .vs-dialog {
    border-radius: 25px;
  }

  .vs-dialog__content {
    border-radius: 25px;
  }

  .wide-popup .vs-dialog {
    height: 100%;
  }
  .vs-dialog__content.notFooter {
    height: 100%;
    margin-bottom: 0 !important;
    padding: 0 !important;
  }
  .doc-accept {
    display: flex;
    padding: 10px 15px;
    background: #66999b;
    border-radius: 0 0 15px 15px;
  }

  .cib-button {
    margin-left: auto;
  }

  .doc-accept .button:hover {
    background: none;
  }
}

ol {
  margin: 0 0 0.2rem 0.7rem;
  padding: 0;
  list-style: decimal !important;
}
